export default {
    install(Vue) {
        // 格式化时间
        Vue.filter("formatDate", function (timestamp) {
            const date = new Date(Number(timestamp) * 1000);
            const year = date.getFullYear();
            const month = date.getMonth() + 1;
            const day = date.getDate();
            const h = date.getHours();
            const m = date.getMinutes();
            const s = date.getSeconds();
            return `${year}-${month < 10 ? `0${month}` : month}-${day < 10 ? `0${day}` : day
                } ${h < 10 ? `0${h}` : h}:${m < 10 ? `0${m}` : m}:${s < 10 ? `0${s}` : s
                }`;
        });

        // 格式化字节
        Vue.filter("formatSizeUnits", function (bytes) {
            if (bytes >= 1073741824) { bytes = (bytes / 1073741824).toFixed(2) + " GB"; }
            else if (bytes >= 1048576) { bytes = (bytes / 1048576).toFixed(2) + " MB"; }
            else if (bytes >= 1024) { bytes = (bytes / 1024).toFixed(2) + " KB"; }
            else if (bytes > 1) { bytes = bytes + "B"; }
            else if (bytes == 1) { bytes = bytes + "B"; }
            else { bytes = "0B"; }
            return bytes;
        });

        // 格式化按钮禁用
        Vue.filter("formatDisabled", function (path, selection) {
            const required = ["edit", "delete", "check","set","off",'daoru'];
            if (required.indexOf(path) >= 0) {
                return selection < 1;
            } else {
                return false;
            }
        });

        // 格式化按钮类型
        Vue.filter("formatType", function (path) {
            const danger = ["delete"];
            if (danger.indexOf(path) >= 0) {
                return "danger";
            } else {
                return "primary";
            }
        });
    }
}