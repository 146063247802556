import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate"
import SecureLS from "secure-ls"
const ls = new SecureLS({ isCompression: false })

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        token: "",
        avatar: "",
        username: "",
        id: "",
        buttons: [],
        keepAlive: [],
        menu:[]
    },
    getters: {
        article_type() {
            // 1新闻中心2社区非常3党建中心4政策法规5知识学习
            return {
                1: '新闻中心',
                // 2: '社区非常',
                3: '党建中心',
                4: '政策法规',
                5: '知识学习',
                6: '志愿者风采'
            }
        },
        center_type() {
            //1党建工作2党建风采3党建知识
            return {
                1: '党建工作',
                2: '党建风采',
                3: '党建知识'
            }
        },
        toupiao_type(){
            //1选举投票2活动投票
            return  {
                1:'选举投票',
                2:'活动投票'
            }
        },
        banner_type(){
            return {
                1:{name:'首页轮播图',size:'750*300',id:1,type:'primary'},
                5:{name:'社区日常轮播图',size:'750*300',id:5,type:'success'},
                6:{name:'党建中心轮播图',size:'750*300',id:6,type:'warning'},
                7:{name:'社区党委图',size:'750*随意高度',id:7,type:'primary'},
            }
        },
    },
    mutations: {
        login(state, payload) {
            state.token = payload.token;
        },
        logout(state) {
            state.token = "";
        },
        setAdminInfo(state, payload) {
            state.avatar = payload.avatar;
            state.username = payload.username;
            state.id = payload.id;
        },
        setButtons(state, payload) {
            state.buttons = payload;
        },
        setKeepAlive(state, alive) {
            state.keepAlive = alive
        },
        setMenu(state, menu){
            state.menu = menu
        }
    },
    actions: {
        setKeepAlive(ctx, keepalice) {
            ctx.commit('setKeepAlive', keepalice)
        },
        setMenu(ctx, menu) {
            ctx.commit('setMenu', menu)
        }
    },
    modules: {
    },
    plugins: [
        createPersistedState({
            key: 'token',
            storage: {
                getItem: key => ls.get(key),
                setItem: (key, value) => ls.set(key, value),
                removeItem: key => ls.remove(key)
            }
        })
    ]
})
